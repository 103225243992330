
const __scriptUrl = document.currentScript.src;

/**
 * Holds configuration values for TourMap.
 */
class TourMapConfiguration {

    /** Create a new configuration object. */
    constructor() {
        /** The base URL of the m1v tile server, which delivers map data to TourMap. */
        this.m1vTileServerBaseUrl = "";
        /** The base URL of the elevation server, which delivers terrain data to TourMap. */
        this.elevationServerBaseUrl = "";
        /** The base URL of the ground textures, which delivers satellite images to TourMap. */
        this.groundTextureBaseUrl = "";
    }
}

// public TourMap API.
var TourMapAPI = {

    /**
     * Starts initialization of TourMap. Do not call any other TourMapAPI functions before the returned
     * promise is fulfilled.
     * @param {Element} canvas - The HTML canvas in which TourMap will be displayed.
     * @param {string} buildUrl - URL at which the folder "Build" is delivered.
     *     If this parameter is not set, the base URL of this script is used.
     * @returns {Promise} promise object that is fulfilled when TourMap is initialized.
     */
    initialize: function(canvas, buildUrl) {

        var dataFilename = "TourMap.data.br";
        var gl2 = canvas.getContext("webgl2");
        if (gl2 && gl2.getExtension('WEBGL_compressed_texture_astc')) {
            dataFilename = dataFilename.replace(".data", "-mobile.data");
        }

        if (!buildUrl)
        {
            buildUrl = __scriptUrl.substr(0, __scriptUrl.lastIndexOf('/'))
        }

        var dataUrl = `${buildUrl}/${dataFilename}`;
        console.log(`TourMap uses data file URL ${dataUrl}`);

        return createUnityInstance(canvas, {
            dataUrl: dataUrl,
            frameworkUrl: `${buildUrl}/TourMap.framework.js.br`,
            codeUrl: `${buildUrl}/TourMap.wasm.br`,
            workerUrl: `${buildUrl}/TourMap.worker.js.br`,
            streamingAssetsUrl: "StreamingAssets",
            companyName: "MBition",
            productName: "TourMap",
            productVersion: "1.24.0-HEAD-20240826",
            matchWebGLToCanvasSize: true,
            devicePixelRatio: isMobile() ? window.devicePixelRatio : 1
        }).then((unityInstance) => {
            __unityInstance = unityInstance;
        });
    },

    /**
     * Set the configuration of TourMap.
     *
     * The configuration must only be set before setting a route URL.
     *
     * @param {Object} configuration - the configuration object.
     */
    setConfiguration: function(configuration) {
        TourMapAPI.callAppControllerJSON("SetConfiguration", configuration);
    },

    /**
     * Load and display the route at the given URL.
     *
     * @param {string} url - URL of the route. Content of the URL needs to be a GeoJson containing the route geometry and route properties.
     * @param {Object} requestHeaders - optional request headers that are sent with the HTTP request.
     */
    setRouteUrl: function(url, requestHeaders) {

        var videoUrl = GetVideoUrl(url);
        if (videoUrl)
        {
            StartVideo(url, requestHeaders, videoUrl);
        }
        else
        {
            if (requestHeaders == undefined)
            {
                TourMapAPI.callAppControllerStringArg("SetRouteUrl", url);
            }
            else
            {
                TourMapAPI.callAppControllerJSON("SetRouteUrlAndHeaders", {
                    "url": url,
                    "requestHeaders": requestHeaders
                });
            }
        }
    },

    /** <summary>
     * Show or hide the debug UI.
     * @param {boolean} show - If true, debug UI will be displayed. If false, debug UI will be hidden.
     */
    showDebugUI: function(show) {
        TourMapAPI.callAppControllerJSON("ShowDebugUI", show);
    },

    /**
     * Move the camera to the annotation having the given id.
     * If the id does not exist, the call is ignored.
     * @param {string} id - id of the annotation, as declared in the GeoJSON file of the annotated route.
     * Cluster ids are also supported by this function.
     */
    moveToAnnotation: function(id) {
        TourMapAPI.callAppControllerStringArg("MoveToAnnotation", id);
    },

    /// Change the relative center of the map.
    /// @param {number} x - The horizontal center of the map.
    /// A value of 0 will move the map center to the left border, 0.5 to the center,
    /// and 1.0 to the right border of the map. Any other value in between is possible.
    /// @param {number} y - The vertical center of the map.
    /// A value of 0 will move the map center to the top border, 0.5 to the center,
    /// and 1.0 to the bottom border of the map. Any other value in between is possible.</param>
    setMapCenter: function(x, y) {
        TourMapAPI.callAppControllerJSON("SetMapCenter", {
            "x": x,
            "y": y
        });
    },


    /**
     * Set the current value for scrolling between two annotations. The annotations
     * are specified by their ids, as declared in the GeoJSON file of the annotated route.
     * This function also supports cluster ids, which allows scrolling between clusters.
     * The camera position will be updated according to the given scroll value.
     *
     * The method supports two general use cases:
     * - A transition between two clusters/POIs: Provide two different IDs with a progress between 0 and 1.
     * - An animation of a single cluster/POI: Provide two identical IDs (of the corresponding cluster/POI)
     *   with a progress between 0 and 1.
     *
     * This method also supports the following fixed IDs:
     * - "route_view": If animated, it shows a top-down overview of the entire route.
     *   It is recommended to use this ID either in a transition towards the first cluster
     *   or in a transition from the last cluster.
     *   Progress on the annotation itself via setAnnotationScrollValue("route_view", "route_view", x) is
     *   not properly supported and will be redirected so that the second id is replaced by the first cluster id.
     *   Staying on the route view can be achieved by simply calling this transition with a progress of 0 though.
     *   Recommendation: Do not rely on this redirection but explicitly use the transition call.
     * - "" <empty_string>: same as "route_view", deprecated!
     * - "globe_view": If animated, it shows a top-down overview of the entire globe.
     *   Similarly to "route_view" this animation also redirects an animation input (two identical IDs)
     *   towards a transition. In this case a call of setAnnotationScrollValue("globe_view", "globe_view", x)
     *   will be redirected to a call of setAnnotationScrollValue("globe_view", "route_view", x).
     *   Currently only transitions towards or from "route_view" are supported. All calls with other
     *   IDs will be redirected to a call towards or from "route_view".
     *   Recommendation: Do not rely on this redirection but explicitly use the proper transition call.
     *
     * @param {string} fromId - Id of the annotation at the start of the scroll transition.
     * @param {string} toId - Id of the annotation at the end of the scroll transition. Can be the
     * same as fromId.
     * @param {number} scrollValue - A value between 0.0 and 1.0, where 0.0 denotes the start
     * of the scrolling, and 1.0 the end of the scrolling for the given annotation pair.
     */
    setAnnotationScrollValue: function(fromId, toId, scrollValue) {
        if (video) {
            ScrollVideo(fromId, toId, scrollValue);
        }
        else
        {
            TourMapAPI.callAppControllerJSON("SetAnnotationScrollValue", {
                "fromId": fromId,
                "toId": toId,
                "scrollValue": scrollValue
            });
        }
    },

    /**
     * This function is called when the map has been loaded completely.
     * You can assign your own function here to get notified when the map has been loaded.
     */
    onMapLoaded: function() {
    },

    /**
     * Aligns the camera such that the two given longitude/latitude coordinates match the the two given window coordinates.
     * @param {{lon: number, lat: number}} wgs84Pos1 - longitude/latitude coordinates that is matched with the first window position.
     * @param {{x: number, y: number}} windowPos1 - the first window position.
     * @param {{lon: number, lat: number}} wgs84Pos2 - longitude/latitude coordinates that is matched with the second window position.
     * @param {{x: number, y: number}} windowPos2 - the second window position.
     */
    setAlignedView: function(wgs84Pos1, windowPos1, wgs84Pos2, windowPos2) {
        if (__unityInstance != null) {
            var canvas = __unityInstance.Module.canvas;
            var ratio = __unityInstance.Module.devicePixelRatio ?? devicePixelRatio;
            TourMapAPI.callAppControllerJSON("SetAlignedView", {
                "wgs84Pos1": wgs84Pos1,
                "screenPos1": {
                    x: (windowPos1.x - canvas.offsetLeft) * ratio,
                    y: (windowPos1.y - canvas.offsetTop) * ratio
                },
                "wgs84Pos2": wgs84Pos2,
                "screenPos2": {
                    x: (windowPos2.x - canvas.offsetLeft) * ratio,
                    y: (windowPos2.y - canvas.offsetTop) * ratio
                }
            });
        }
    },

    callAppControllerNoArg: function(functionName) {
        if (__unityInstance != null) {
            __unityInstance.SendMessage("AppControllerJson", functionName);
        }
        else
        {
            console.error("TourMap is not initialized, please call TourMapAPI.initialize before calling any other API function.");
        }
    },

    callAppControllerStringArg: function(functionName, argument) {
        if (__unityInstance != null) {
            __unityInstance.SendMessage("AppControllerJson", functionName, argument);
        }
        else
        {
            console.error("TourMap is not initialized, please call TourMapAPI.initialize before calling any other API function.");
        }
    },

    callAppControllerJSON: function(functionName, params) {
        if (__unityInstance != null) {
            __unityInstance.SendMessage("AppControllerJson", functionName, JSON.stringify(params));
        }
        else
        {
            console.error("TourMap is not initialized, please call TourMapAPI.initialize before calling any other API function.");
        }
    },

    onTestDone: function(testResults) {
    },

    startTest: function() {
        if (__unityInstance != null) {
            __unityInstance.SendMessage("TestController", "StartTest");
        }
    },

    setUnityInstance: function(instance) {
        console.error("TourMapAPI.setUnityInstance is deprecated. Use TourMapAPI.initialize instead.")
        console.log(`setUnityInstance(${instance})`);
        __unityInstance = instance;
    }

}

// private section
var __unityInstance;

function isMobile() {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
}

/**
 * Implementation for video scrolling.
 */

var videoList = [
    ["00000000-970c-4300-9f98-00000015ad39", "https://download.mbition.de/tourmap/Konstant-to-Freiburg-Tour-video-00000000-970c-4300-9f98-00000015ad39.mp4"],
];
var video;
var scrollSegments = [];

function GetVideoUrl(routeUrl)
{
    var entry = videoList.find(v => routeUrl.includes(v[0]));
    if (entry)
    {
        return entry[1];
    }
    else
    {
        return undefined;
    }
}

function StartVideo(routeUrl, requestHeaders, videoUrl)
{
    __unityInstance.Quit().then(() => {
        __unityInstance = null;
        ReplaceWebGLWithVideo(videoUrl);
        LoadClusterIds(routeUrl, requestHeaders);
    });
}

function ReplaceWebGLWithVideo(videoUrl)
{
    var canvasDiv = document.getElementById("unity-canvas").parentNode;
    canvasDiv.innerHTML = `
        <video id="video" style="width: 100%;" autobuffer preload autoplay>
            <source type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;" src="${videoUrl}"></source>
        </video>
    `;

    video = document.getElementById('video');
    video.pause();
}

function LoadClusterIds(routeUrl, requestHeaders)
{
    getJSON(routeUrl, requestHeaders, (err, data) => {
        if (err !== null) {
            console.error('Could not load route: ' + err);
        } else {
            scrollSegments = GetScrollSegments(data);
        }
    });
}

function ScrollVideo(fromId, toId, scrollValue)
{
    console.log(`SetAnnotationScrollValue(${fromId},${toId},${scrollValue})`)

    scrollValue = Math.min(Math.max(scrollValue, 0), 1);
    var index = scrollSegments.findIndex(s => s.fromId == fromId && s.toId == toId);
    if (index >= 0)
    {
        progress = (index + scrollValue) / scrollSegments.length;
        video.currentTime = progress * video.duration;
    }
    else
    {
        console.error(`video segment for transition between ${fromId} and ${toId} not found`);
        if (fromId == "" && toId == "")
        {
            video.currentTime = 0;
        }
    }
}

/**
 * Some utility functions for loading a route.
 */

function getScrollSegments(routeData)
{
    // sort cluster features
    const clusterFeatures = []
    for (feature of routeData.features) {
        if (feature.properties.landmark_type == "CLUSTER" && feature.properties.cluster_usage_type == "WEBSITE") {
            clusterFeatures.push(feature);
        }
    }
    clusterFeatures.sort((a, b) => a.properties.route_position - b.properties.route_position);

    // fill scroll segments
    const scrollSegments = [];
    var lastClusterId = "route_view";
    for (clusterFeature of clusterFeatures) {
        let clusterId = clusterFeature.id;
        console.log(`cluster ${clusterId} at position ${clusterFeature.properties.route_position}`);
        scrollSegments.push({fromId: lastClusterId, toId: clusterId});
        scrollSegments.push({fromId: clusterId, toId: clusterId});
        lastClusterId = clusterId;
    }
    scrollSegments.push({fromId: lastClusterId, toId: "route_view"});
    return scrollSegments;
}

function getJSON(url, requestHeaders, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    if (requestHeaders) {
        for (const [key, value] of Object.entries(requestHeaders)) {
            xhr.setRequestHeader(key, value);
        }
    }
    xhr.responseType = 'json';
    xhr.onload = function() {
        var status = xhr.status;
        if (status === 200) {
            callback(null, xhr.response);
        } else {
            callback(status, xhr.response);
        }
    };
    xhr.send();
};
